.swiper {
	opacity: 0;
	transition: $ms-faster all;
}

.swiper-initialized {
	opacity: 1;
}

// pagination
.swiper-pagination {
	position: relative;
	margin-top: 1rem;

	@include bp-xl {
		margin-top: 1.25rem;
	}

	&-bullets {
		--swiper-pagination-bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-bullet {
		--swiper-pagination-bullet-horizontal-gap: 0;
		--swiper-pagination-bullet-width: 1.125rem;
		--swiper-pagination-bullet-height: 0.1875rem;
		--swiper-pagination-bullet-border-radius: 0;
		--swiper-theme-color: #{$primary};
		--swiper-pagination-bullet-inactive-opacity: 1;
		--swiper-pagination-bullet-inactive-color: #{$gray-40};

		transition: $ms-faster all;

		@include bp-md {
			--swiper-pagination-bullet-width: 1.875rem;
			--swiper-pagination-bullet-height: 0.3125rem;
		}

		@include bp-xl {
			--swiper-pagination-bullet-width: 2.5rem;
			--swiper-pagination-bullet-height: 0.4375rem;
		}
	}

	&-lock {
		margin: 0;
	}

	&.justify-end {
		justify-content: flex-end;
	}
}

// button
.swiper-ctrl {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&.sm-lg {
		--swiper-navigation-size: 3.25rem;
		font-size: 1.25rem;
		@include bp-xl {
			--swiper-navigation-size: 6.5rem;
			font-size: 2.5rem;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		--swiper-navigation-sides-offset: 0;

		position: relative;
		top: auto;
		left: auto;
		right: auto;
		transform: none;
	}
}

.swiper-button-prev,
.swiper-button-next {
	margin-top: 0;
	top: 50%;
	transform: translateY(-50%);
	color: $white;
	background-color: $dark;
	border: 0.0625rem solid $dark;
	width: var(--swiper-navigation-size);
	transition: color $ms-faster;

	&.swiper-button-disabled {
		opacity: 1;
		color: $gray-500;
	}

	&::after {
		content: none;
	}

	@include bp-lg {
		transition: $ms-faster all;
		&:hover {
			border-color: $primary;
			background-color: $primary;
		}
	}

	// 透明的
	&.is-transparent {
		font-size: 2.5rem;
		color: $dark;
		border: 0;
		background-color: transparent;

		&.swiper-button-disabled {
			color: $gray-200;
		}
	}
}

.swiper-button-prev {
	left: auto;
	right: 100%;

	@include bp-lg {
		right: calc(100% + 0.75rem);
	}

	@include bp-xl {
		right: calc(100% + 1.5rem);
	}
}

.swiper-button-next {
	left: 100%;
	right: auto;

	@include bp-lg {
		left: calc(100% + 0.75rem);
	}

	@include bp-xl {
		left: calc(100% + 1.5rem);
	}
}
