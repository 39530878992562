@import 'core/variables';
@import 'core/functions';
@import 'core/mixins';
@import 'extension';

// Swiper
@import 'vendor/swiper/swiper';

// --------------------------------------------------------

// Headline
.section {
	padding: 3rem 0;

	@include bp-xl {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	@include bp-max {
		padding-top: 6.875rem;
		padding-bottom: 6.875rem;
	}

	&_headline {
		position: relative;
		z-index: 1;
		margin-bottom: 2.25rem;

		@include bp-xl {
			margin-bottom: 3.5rem;
		}

		@include bp-max {
			margin-bottom: 5.25rem;
		}

		.flexbox {
			margin-top: 16px;

			.section_description {
				margin-top: 0;
			}

			@include bp-md {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			@include bp-max {
				margin-top: 2rem;
			}
		}

		&_btn {
			position: absolute;
			top: 0;
			right: 0;

			@media (max-width: 63.9375rem) {
				padding: 0.5rem 1rem;
				min-width: 0;
				min-height: 0;
				font-size: 0.875rem;
			}

			@include bp-md {
				min-width: 9.375rem;
			}

			@include bp-lg {
				position: relative;
				box-shadow: 2px 2px 30px 0 rgba($dark, 0.04);
			}
		}
	}

	&_description {
		margin-top: 1rem;
		margin-bottom: 0;
		color: $gray-500;

		@include bp-max {
			margin-top: 2rem;
		}
	}
}

// --------------------------------------------------------

.section-kv {
	--bottomline-pos: 0.375rem;

	position: relative;
	padding: 0;

	@include bp-xl {
		--bottomline-pos: 0.75rem;

		margin: 0 auto;
		max-width: 120rem;
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 2;
		bottom: calc(var(--bottomline-pos) * -1);
		right: 0;
		display: block;
		width: 100%;
		height: 0.375rem;
		background-color: $primary;

		@include bp-md {
			max-width: 101.875rem;
			height: 0.75rem;
		}
	}

	.card {
		position: relative;
		display: block;

		@include bp-md {
			margin-left: auto;
			max-width: percentage(1550 / 1920);
		}
	}

	.embed {
		&::before {
			padding-top: percentage(520 / 375);

			@include bp-md {
				padding-top: percentage(780 / 1550);
			}
		}
	}

	// Headline
	.section_headline {
		position: absolute;
		z-index: 1;
		bottom: percentage(92 / 520);
		left: 0;
		margin: 0;
		padding: 0 1.5rem;

		@include bp-md {
			top: percentage(220 / 780);
			bottom: auto;
			left: 3rem;
			padding: 0;
		}

		@media (min-width: 100rem) {
			left: -3rem;
		}

		@include bp-max {
			left: -7.5rem;
		}
	}

	.section_title {
		position: relative;
		z-index: 2;
		margin-bottom: -0.25rem;
		padding: 0.625rem 1rem;
		width: 16.25rem;
		color: $white;
		background-color: $dark;
		box-shadow: 2px 2px 30px 0 rgba($dark, 0.08);

		@include bp-lg {
			padding: 1.25rem 2rem;
			width: 22.5rem;
		}

		@include bp-xl {
			width: 28.75rem;
		}

		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			top: calc(100% - 0.375rem);
			left: 0.5625rem;
			display: block;
			width: 0.375rem;
			height: 2.75rem;
			background-color: $primary;

			@include bp-md {
				top: calc(100% - 0.625rem);
				left: 2.5rem;
				width: 0.75rem;
				height: 3.75rem;
			}

			@include bp-lg {
				height: 5.5rem;
			}
		}
	}

	.section_description {
		margin: 0;
		margin-left: 0.75rem;
		padding: 0.625rem 1rem;
		max-width: 19.6875rem;
		background-color: $white;
		box-shadow: 2px 2px 30px 0 rgba($dark, 0.08);

		@include bp-md {
			margin-left: 2.875rem;
			padding: 1.25rem 2rem;
		}

		@include bp-lg {
			max-width: 32.5rem;
		}
	}

	// Swiper
	.swiper-ctrl {
		position: absolute;
		z-index: 5;
		bottom: calc(var(--bottomline-pos) * -1);
		right: 0;
	}

	.swiper-pagination {
		position: absolute;
		z-index: 5;
		bottom: 0.75rem;
		right: 0;
		margin: 0;

		@include bp-md {
			bottom: 2rem;
		}

		@include bp-lg {
			bottom: 3.375rem;
		}
	}
}

.section-kv.js-ani {
	opacity: 0;
	transition: 0.8s opacity;

	.card {
		transform: scale(1.05);
		transition: 1.2s transform;
	}

	&.is-animated {
		opacity: 1;

		.card {
			transform: scale(1);
		}
	}
}

// --------------------------------------------------------

.section-feature {
	// Headline
	.section_headline {
		margin-bottom: 3rem;

		@include bp-lg {
			margin: 6rem auto 11.25rem;
			max-width: 46.875rem;
		}

		@include bp-xl {
			margin-top: 6.875rem;
			margin-bottom: 15.9375rem;
			max-width: none;
		}
	}

	.section_description {
		@include bp-xl {
			padding: 0 6.25rem;
		}
	}

	.dividing {
		--pos-dis: 4.375rem;

		margin-right: auto;
		margin-left: auto;

		@include bp-lg {
			position: absolute;
		}

		&:first-of-type {
			margin-bottom: 3rem;

			@include bp-lg {
				top: calc(var(--pos-dis) * -1);
				left: calc(var(--pos-dis) * -0.5);
				margin: 0;
			}

			@include bp-xl {
				top: calc(var(--pos-dis) * -2);
				left: calc(var(--pos-dis) * 2);
			}
		}

		&:last-of-type {
			margin-top: 3rem;

			@include bp-lg {
				bottom: calc(var(--pos-dis) * -1.5);
				right: calc(var(--pos-dis) * -0.5);
				margin: 0;
			}

			@include bp-xl {
				bottom: calc(var(--pos-dis) * -3);
				right: calc(var(--pos-dis) * 2);
			}
		}
	}

	// Body
	.section_body {
		position: relative;
		padding-bottom: 1.5rem;

		@include bp-lg {
			padding: 0;
		}

		.container {
			@include bp-lg {
				padding: 0 6.25rem;
			}

			@include bp-max {
				padding: 0;
			}
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 11.875rem;
			background-color: $gray-40;
			pointer-events: none;

			@include bp-lg {
				top: 50%;
				height: 18.75rem;
				transform: translateY(-50%);
			}
		}
	}

	.card {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 14.625rem;
		height: 100%;

		@include bp-md {
			width: 100%;
			max-width: 21.875rem;
		}

		@include bp-lg {
			&::before {
				content: '';
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				background: linear-gradient(0deg, $black 0%, rgba($white, 0) 100%);
				opacity: 0;
				transition: $ms-fast all;
			}

			&:hover {
				.card_txt {
					opacity: 1;
					pointer-events: all;
					transform: translateY(0);
				}

				&::before {
					opacity: 1;
				}
			}
		}

		@include bp-xl {
			margin: 0 auto;
			width: 21.875rem;
		}

		&_txt {
			flex: 1;

			@include bp-lg {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				display: flex;
				align-items: flex-end;
				margin: 0;
				width: 100%;
				height: 100%;
				font-size: 1rem;
				line-height: 1.55;
				color: $gray-40;
				background-color: transparent;
				opacity: 0;
				transform: translateY(-0.9375rem);
				transition: $ms-fast all;
				pointer-events: none;
			}

			@include bp-xl {
				font-size: 1.375rem;
			}
		}
	}

	.embed {
		position: relative;
		flex-shrink: 0;

		&::before {
			padding-top: percentage(175 / 234);

			@include bp-lg {
				padding-top: percentage(487 / 350);
			}
		}

		&_title {
			--clip-height: 1.125rem;

			position: absolute;
			z-index: 2;
			top: calc(var(--clip-height) * -1);
			left: -0.375rem;
			padding: 0.25rem 1.375rem 0.25rem 0.5rem;
			color: $white;
			background-color: $primary;
			clip-path: polygon(
				0 0,
				100% 0,
				100% calc(100% - var(--clip-height)),
				calc(100% - var(--clip-height)) 100%,
				0 100%
			);

			@include bp-lg {
				--clip-height: 1.4375rem;
			}

			@include bp-xl {
				--clip-height: 2rem;

				left: -3.75rem;
				padding: 0.625rem 2.625rem 0.625rem 1rem;
			}
		}
	}

	// Swiper
	.swiper {
		overflow: visible;
	}

	.swiper-outter {
		margin-bottom: 2.5rem;
		padding: 0 0.5rem;

		@include bp-lg {
			margin-right: auto;
			margin-left: auto;
			max-width: 75rem;
		}

		@include bp-xl {
			margin-bottom: 0;
			padding-left: 3.75rem;
			max-width: none;
		}
	}

	.swiper-slide {
		--height-distance: -4.375rem;

		width: auto;
		height: auto;

		@include bp-md {
			width: 33.333%;
		}

		@include bp-lg {
			&:first-child {
				top: var(--height-distance);
			}

			&:last-child {
				bottom: var(--height-distance);
			}
		}
	}
}

// Animate
.section-feature.js-ani {
	$animateEl: '.dividing', '.section_title, .section_description', '.section_body';

	@include set-animate($animateEl);

	.dividing {
		max-height: 0;

		&:first-child {
			transform: translate(-5%, -5%);
		}

		&:last-child {
			transform: translate(5%, 5%);
		}
	}

	&.is-animated {
		@include set-animated($animateEl);

		.dividing {
			max-height: 17.625rem;
			transform: rotate(40deg);
			transition: 1s max-height, 0.5s transform $ms-fast;
		}
	}
}

// --------------------------------------------------------

.section-news {
	overflow: hidden;

	@include bp-max {
		padding-bottom: 4.5rem;
	}

	.section_headline {
		margin-bottom: 3.25rem;

		@include bp-md {
			margin-bottom: 4rem;
		}

		@include bp-max {
			margin-bottom: 6.75rem;
		}
	}

	.news-list {
		@extend %list-reset;

		position: relative;

		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: 0;
			display: block;
			width: calc(100vw - 1.5rem);
			height: calc(100% + 1rem);
			background-color: $gray-40;

			@include bp-md {
				left: 3.125rem;
				width: 100vw;
				height: calc(100% + 1.5rem);
			}
		}
	}

	.news {
		&_tag {
			@include bp-md {
				margin-bottom: 0;
				margin-right: 1.5rem;
			}

			@include bp-lg {
				min-width: 8.125rem;
				font-size: 1rem;
			}

			@include bp-xl {
				--clip-height: 1.25rem;

				padding: 0.5rem 1.5rem 0.5rem 0.625rem;
			}
		}

		&_title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0;
			width: 100%;

			.be-icon {
				margin-left: 0.625rem;
				font-size: 1.75rem;
				transition: $ms-fast all;

				@include bp-xl {
					font-size: 2rem;
				}
			}
		}
	}

	.dividing {
		margin: 3rem auto 0;

		@include bp-max {
			margin-top: 4.5rem;
		}
	}
}

.section-news.js-ani {
	$animateEl: '.section_headline', '.news-list', '.dividing';

	@include set-animate($animateEl);

	.dividing {
		opacity: 0;
		transform: translate(-5%, -5%);
	}

	&.is-animated {
		@include set-animated($animateEl);

		.dividing {
			opacity: 1;
			transform: rotate(40deg);
			transition-duration: $ms;
		}
	}
}

// --------------------------------------------------------

.section-report {
	position: relative;
	padding-top: 0;

	.section_headline {
		margin-bottom: 4.875rem;

		@include bp-md {
			margin-bottom: 2rem;
		}

		@include bp-xl {
			margin-bottom: 3.5rem;
		}

		@include bp-max {
			margin-bottom: 5.25rem;
		}
	}

	.section_body {
		position: relative;
		padding-bottom: 1.5rem;

		@include bp-md {
			padding-bottom: 2rem;
		}

		@include bp-xl {
			padding-bottom: 4.5rem;
		}
	}

	.section_bg {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 20.5625rem;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@include bp-xl {
			height: 26.875rem;
		}
	}

	.report_title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		padding: 1.5rem 1rem;
		color: $dark;
		background-color: $white;
		transition: $ms-fast all;

		&[href] {
			cursor: pointer;
		}

		@include bp-md {
			padding: 1rem 0.75rem;
			height: 100%;
			box-shadow: 2px 2px 20px 0 rgba($dark, 0.15);
		}

		@include bp-lg {
			padding: 2rem 1.25rem;

			&:is(a):hover {
				color: $white;
				background-color: $dark;

				.be-icon {
					opacity: 1;
					transform: translateX(0);
				}
			}
		}

		@media (min-width: 100rem) {
			padding: 2.875rem 1.25rem;
		}

		.be-icon {
			pointer-events: none;
			font-size: 1.5rem;
			transform: translateX(-50%);
			transition: $ms-fast all;

			@include bp-md {
				display: none;
				opacity: 0;
			}

			@media (min-width: 75rem) {
				display: block;
			}

			@include bp-xl {
				font-size: 2.5rem;
			}
		}
	}

	.img {
		display: block;
	}

	// Swiper
	.swiper:not(.thumb-swiper) {
		width: calc(100vw - 1.5rem);
		background-color: $white;

		@include bp-md {
			pointer-events: none;
			width: 100%;
		}

		.swiper-slide {
			opacity: 1 !important;
		}
	}

	.thumb-swiper {
		position: absolute;
		top: 0;
		left: 1.5rem;
		overflow: visible;
		max-width: calc(100% - 5rem);
		transform: translateY(-50%);

		.swiper-slide {
			width: 17.5rem;

			@include bp-md {
				width: auto;
			}
		}

		@include bp-md {
			position: relative;
			left: auto;
			overflow: visible;
			margin: -3rem auto 0;
			max-width: calc(100% - 1rem);
			background-color: transparent;
			transform: none;
		}

		@include bp-lg {
			max-width: calc(100% - 2rem);
		}

		@media (min-width: 100rem) {
			margin-top: -6.125rem;
			max-width: calc(100% - 6.75rem);
		}
	}

	.swiper-slide {
		@include bp-md {
			height: auto;
		}
	}

	.swiper-slide-active {
		.report_title {
			@media (max-width: 47.9375rem) {
				color: $white;
				background-color: $dark;
			}
		}

		.be-icon {
			opacity: 1;
			transform: translateX(0);

			@include bp-md {
				opacity: 0;
			}
		}
	}
}

.section-report.js-ani {
	$animateEl: '.section_headline', '.section_body';

	@include set-animate($animateEl);

	&.is-animated {
		@include set-animated($animateEl);
	}
}

// --------------------------------------------------------

.section-notice {
	position: relative;

	// Body
	.section_body {
		position: relative;
		padding-bottom: 1.5rem;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 12.5rem;
			background-color: $gray-40;
			pointer-events: none;

			@include bp-lg {
				height: calc(100% - 4.5rem);
			}
		}

		@include bp-lg {
			padding-bottom: 4.5rem;
		}
	}

	.card {
		display: flex;
		max-width: 18.75rem;

		@include bp-md {
			max-width: none;
		}

		&_txt {
			@include bp-xl {
				padding: 1.25rem;
			}
		}
	}

	// Swiper
	.swiper {
		overflow: visible;

		&.is-locked {
			cursor: auto !important;
			color: inherit !important;

			.card-article {
				pointer-events: none;
			}
		}
	}

	.swiper-slide {
		width: auto;
		height: auto;
	}
}

.section-notice.js-ani {
	$animateEl: '.section_headline', '.section_body';

	@include set-animate($animateEl);

	&.is-animated {
		@include set-animated($animateEl);
	}
}

// --------------------------------------------------------

.section-tools {
	@include bp-lg {
		padding-bottom: 6.875rem;
	}

	@include bp-max {
		padding-bottom: 13.75rem;
	}

	.section_headline {
		margin-bottom: 3.25rem;

		@include bp-lg {
			margin-bottom: 4.5rem;
		}

		@include bp-max {
			margin-bottom: 6.75rem;
		}
	}

	.section_body {
		position: relative;
		margin-bottom: 2.25rem;

		@include bp-lg {
			margin-bottom: 3.75rem;
		}

		@include bp-xl {
			margin-bottom: 0;
		}

		.container {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.section_bg {
		position: relative;
		width: 100%;
		height: 11.125rem;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(219, 71, 51, 85%);
			opacity: 0.85;
		}

		@include bp-xl {
			height: 32.25rem;
		}
	}

	.infoitem {
		$root: '.infoitem';

		width: 14.6875rem;
		max-width: 14.6875rem;
		height: 100%;

		@include bp-lg {
			width: 17rem;
			max-width: 17rem;
		}

		@include bp-xl {
			--clr-dark: #{$gray-40};
			--clr-white: #{$dark};

			padding: 2.25rem 1.25rem;
			width: auto;
			max-width: 16rem;
			height: auto;
			min-height: 17.5rem;
			border-color: $gray-200;
			transition: $ms cubic-bezier(0.25, 1, 0.5, 1);

			&:hover {
				--margin-pos: 0;
				--clr-white: #{$white};
				--clr-dark: #{$dark};

				min-height: 35.25rem;
				border-color: var(--clr-dark);

				#{$root}_info {
					max-height: 18.75rem;
					opacity: 1;
					transform: translateY(0);
					transition: $ms-slower ease-out 0.3s;
				}

				#{$root}_title {
					&::before {
						width: 7.875rem;
					}

					&::after {
						opacity: 0;
					}
				}
			}
		}

		&_info {
			@include bp-xl {
				position: relative;
				opacity: 0;
				transform: translateY(-2rem);
			}
		}
	}

	// Swiper
	.swiper {
		overflow: visible;

		@include bp-xl {
			width: 100%;
		}
	}

	.swiper-slide {
		width: auto;
		height: auto;

		@include bp-xl {
			--margin-pos: 5rem;

			display: flex;
			align-items: center;

			&:nth-child(odd) {
				.infoitem {
					margin-top: var(--margin-pos);
				}
			}

			&:nth-child(even) {
				.infoitem {
					margin-top: calc(var(--margin-pos) * -1);
				}
			}
		}
	}

	.swiper-pagination {
		margin-top: 4rem;

		@include bp-lg {
			margin-top: 5rem;
		}
	}
}

.section-tools.js-ani {
	$animateEl: '.section_headline', '.section_body';

	@include set-animate($animateEl);

	&.is-animated {
		@include set-animated($animateEl);
	}
}

// --------------------------------------------------------

.section-join {
	$this: '.section';

	position: relative;
	padding-top: 7.375rem;
	padding-bottom: 0;
	color: $white;

	&::before {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, $white 3%, rgba($black, 0) 87%);
	}

	@include bp-md {
		padding: 12.5rem 0;
		background-position: 65% center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	#{$this}_bg {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	#{$this}_headline {
		margin: 0 auto;
		padding: 0.625rem 1rem;
		width: calc(100% - 3.75rem);
		max-width: 19.5rem;
		background-color: $dark;
		box-shadow: 2px 2px 30px 0 rgba($dark, 0.04);

		@include bp-md {
			margin-bottom: -1rem;
			padding: 1.25rem 2rem;
			width: auto;
			max-width: 21.875rem;
		}

		@include bp-lg {
			max-width: 32rem;
		}
	}

	#{$this}_title {
		margin: 0;
		line-height: 1.4;
	}

	#{$this}_body {
		position: relative;
		margin: 0 auto;
		padding: 1rem 1.5rem;
		max-width: 73.75rem;
		background-color: rgba($primary, 0.85);

		@include bp-md {
			padding: 3rem;
			width: calc(100% - 6.25rem);
		}

		@include bp-lg {
			padding: 3rem;
		}
	}

	.description {
		margin: 1rem 0 0;
		font-size: 0.875rem;
		line-height: 1.43;

		@include bp-md {
			font-size: 1.375rem;
			line-height: 1.55;
		}
	}
}

.section-join.js-ani {
	$animateEl: '.section_headline', '.section_body';

	@include set-animate($animateEl);

	.section_body {
		transform: none;
	}

	&.is-animated {
		@include set-animated($animateEl);
	}
}

// --------------------------------------------------------

[lang*='ja'] {
	.section_headline_btn {
		@include bp-lg {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	
	.section-join {
		.section_headline {
			@include bp-md {
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
			}

			@include bp-lg {
				max-width: 46.25rem;
			}
		}

		.section_title {
			@include bp-xl {
				font-size: remy(42);
				line-height: (60 / 42);
			}
		}
	}
}
